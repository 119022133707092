var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-notes" } }),
              _vm._v(" アカウント登録 ")
            ],
            1
          ),
          _c(
            "CForm",
            [
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { lg: "6" } },
                        [
                          _c("CInput", {
                            attrs: { label: "ユーザーコード", placeholder: "" },
                            model: {
                              value: _vm.account.code,
                              callback: function($$v) {
                                _vm.$set(_vm.account, "code", $$v)
                              },
                              expression: "account.code"
                            }
                          }),
                          _c("CInput", {
                            attrs: {
                              label: "会社名",
                              placeholder: "オープンクーポン株式会社"
                            },
                            model: {
                              value: _vm.account.name,
                              callback: function($$v) {
                                _vm.$set(_vm.account, "name", $$v)
                              },
                              expression: "account.name"
                            }
                          }),
                          !_vm.isEditOthers()
                            ? _c("CInput", {
                                attrs: {
                                  label: "メールアドレス",
                                  type: "email",
                                  lazy: true,
                                  isValid: _vm.checkIfValid("email"),
                                  placeholder: "taro@coupon.com",
                                  autocomplete: "email",
                                  invalidFeedback:
                                    "メールアドレスを正しく入力してください。"
                                },
                                model: {
                                  value: _vm.auth.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.auth, "email", $$v)
                                  },
                                  expression: "auth.email"
                                }
                              })
                            : _vm._e(),
                          _vm.isEditOthers()
                            ? _c("CInput", {
                                attrs: {
                                  label: "メールアドレス",
                                  type: "email",
                                  plaintext: ""
                                },
                                model: {
                                  value: _vm.auth.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.auth, "email", $$v)
                                  },
                                  expression: "auth.email"
                                }
                              })
                            : _vm._e(),
                          !_vm.isEditOthers()
                            ? _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("CInput", {
                                        attrs: {
                                          label: "パスワード",
                                          lazy: true,
                                          isValid: _vm.checkIfValid("password"),
                                          type: "password",
                                          placeholder: "Password",
                                          autocomplete: "new-password",
                                          invalidFeedback:
                                            "8文字以上かつ数字、英小文字それぞれ1文字以上含む値を入力してください"
                                        },
                                        model: {
                                          value: _vm.auth.password,
                                          callback: function($$v) {
                                            _vm.$set(_vm.auth, "password", $$v)
                                          },
                                          expression: "auth.password"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCol",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("CInput", {
                                        attrs: {
                                          label: "パスワード確認入力",
                                          lazy: true,
                                          isValid: _vm.checkIfValid(
                                            "confirmPassword"
                                          ),
                                          type: "password",
                                          placeholder: "Password",
                                          autocomplete: "new-password",
                                          invalidFeedback:
                                            "パスワードが一致しません。"
                                        },
                                        model: {
                                          value: _vm.auth.confirmPassword,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.auth,
                                              "confirmPassword",
                                              $$v
                                            )
                                          },
                                          expression: "auth.confirmPassword"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          this.isNew
                            ? _c("CSelect", {
                                attrs: {
                                  label: "権限",
                                  options: _vm.options,
                                  placeholder: "Please select",
                                  value: _vm.account.role
                                },
                                on: {
                                  "update:value": [
                                    function($event) {
                                      return _vm.$set(
                                        _vm.account,
                                        "role",
                                        $event
                                      )
                                    },
                                    function($event) {
                                      return _vm.onUpdateRole($event)
                                    }
                                  ]
                                }
                              })
                            : _vm._e(),
                          !this.isNew
                            ? _c("div", [
                                _vm._v(
                                  "権限 " +
                                    _vm._s(_vm.getRoleName(_vm.account.role))
                                )
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "form-group form-row" },
                            [
                              _vm.isDistribution()
                                ? _c(
                                    "CCol",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { sm: "2", tag: "label" }
                                    },
                                    [_vm._v(" 公開日 ")]
                                  )
                                : _vm._e(),
                              _vm.isDistribution()
                                ? _c(
                                    "CCol",
                                    {
                                      staticClass: "form-inline",
                                      attrs: { sm: "10" }
                                    },
                                    [
                                      _c("CInput", {
                                        attrs: {
                                          type: "date",
                                          horizontal: "",
                                          isValid: _vm.checkOpeningDate(),
                                          invalidFeedback: "不正な日付です。"
                                        },
                                        model: {
                                          value: _vm.account.opening_date,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.account,
                                              "opening_date",
                                              $$v
                                            )
                                          },
                                          expression: "account.opening_date"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("br"),
                          _c("br"),
                          _c(
                            "CRow",
                            { staticClass: "align-items-center" },
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "text-left",
                                  attrs: { col: "2" }
                                },
                                [
                                  _c(
                                    "CButton",
                                    {
                                      attrs: { color: "info" },
                                      on: { click: _vm.submit }
                                    },
                                    [_vm._v(" 登録 ")]
                                  )
                                ],
                                1
                              ),
                              this.isDeletable()
                                ? _c(
                                    "CCol",
                                    {
                                      staticClass: "text-left",
                                      attrs: { col: "4" }
                                    },
                                    [
                                      _c(
                                        "CButton",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { color: "info" },
                                          on: {
                                            click: _vm.confirmPasswordReset
                                          }
                                        },
                                        [_vm._v(" パスワードリセット ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              this.isDeletable()
                                ? _c(
                                    "CCol",
                                    {
                                      staticClass: "text-right",
                                      attrs: { col: "6" }
                                    },
                                    [
                                      _c(
                                        "CButton",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { color: "danger" },
                                          on: {
                                            click: function($event) {
                                              _vm.dangerModal = true
                                            }
                                          }
                                        },
                                        [_vm._v("削除")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("br")
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "CRow",
                            [
                              _c(
                                "CCol",
                                { attrs: { sm: "4" } },
                                [
                                  _c("CInput", {
                                    attrs: {
                                      label: "郵便番号",
                                      placeholder: "100-0001"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.onUpdatePostalCode($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.account.postal_code,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.account,
                                          "postal_code",
                                          $$v
                                        )
                                      },
                                      expression: "account.postal_code"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                { attrs: { sm: "8" } },
                                [
                                  _c("CSelect", {
                                    attrs: {
                                      label: "都道府県",
                                      options: _vm.prefectures,
                                      placeholder: "Please select"
                                    },
                                    model: {
                                      value: _vm.account.prefCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.account, "prefCode", $$v)
                                      },
                                      expression: "account.prefCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("CInput", {
                            attrs: {
                              label: "住所",
                              placeholder: "市区町村以下"
                            },
                            model: {
                              value: _vm.account.address,
                              callback: function($$v) {
                                _vm.$set(_vm.account, "address", $$v)
                              },
                              expression: "account.address"
                            }
                          }),
                          _c("CInput", {
                            attrs: {
                              label: "電話番号",
                              placeholder: "0300000000"
                            },
                            model: {
                              value: _vm.account.phone_number,
                              callback: function($$v) {
                                _vm.$set(_vm.account, "phone_number", $$v)
                              },
                              expression: "account.phone_number"
                            }
                          }),
                          _vm.isDistribution() && this.account.companyCode === 0
                            ? _c("CInput", {
                                attrs: {
                                  label: "企業コード",
                                  lazy: true,
                                  isValid: _vm.checkIfValid(
                                    "displayCompanyCode"
                                  ),
                                  invalidFeedback:
                                    "企業コードを正しく入力してください。"
                                },
                                model: {
                                  value: _vm.auth.displayCompanyCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.auth,
                                      "displayCompanyCode",
                                      $$v
                                    )
                                  },
                                  expression: "auth.displayCompanyCode"
                                }
                              })
                            : _vm._e(),
                          _vm.isDistribution() && this.account.companyCode > 0
                            ? _c("CInput", {
                                attrs: {
                                  label: "企業コード",
                                  lazy: true,
                                  isValid: _vm.checkIfValid(
                                    "displayCompanyCode"
                                  ),
                                  invalidFeedback:
                                    "企業コードを正しく入力してください。",
                                  plaintext: ""
                                },
                                model: {
                                  value: _vm.auth.displayCompanyCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.auth,
                                      "displayCompanyCode",
                                      $$v
                                    )
                                  },
                                  expression: "auth.displayCompanyCode"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !this.isNew && (_vm.isMakers() || _vm.isWholeSales())
        ? _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-notes" } }),
                  _vm._v(" 取引店舗登録 ")
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        [
                          _c(
                            "CForm",
                            _vm._l(this.distributions, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  attrs: { item: item, index: index }
                                },
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        { attrs: { md: "5" } },
                                        [
                                          _c("CSelect", {
                                            attrs: {
                                              horizontal: "",
                                              options:
                                                _vm.distributionsPullDown,
                                              placeholder: "Please select",
                                              value: item.id
                                            },
                                            on: {
                                              "update:value": [
                                                function($event) {
                                                  return _vm.$set(
                                                    item,
                                                    "id",
                                                    $event
                                                  )
                                                },
                                                function($event) {
                                                  return _vm.onUpdateDistributionsForMaker(
                                                    index,
                                                    $event
                                                  )
                                                }
                                              ]
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "CCol",
                                        { attrs: { md: "1" } },
                                        [
                                          _c(
                                            "CButton",
                                            {
                                              staticClass: "ml-3 mb-3",
                                              attrs: { color: "dark" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteDistribution(
                                                    item.id
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("×")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { staticClass: "mb-3 mb-xl-0", attrs: { col: "2" } },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: {
                                variant: "outline",
                                shape: "pill",
                                color: "secondary",
                                size: "sm"
                              },
                              on: { click: _vm.addDistribution }
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v("入力欄を追加 ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "m-3",
                          attrs: { color: "info" },
                          on: { click: _vm.submitDistributions }
                        },
                        [_vm._v(" 登録する ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !this.isNew &&
      (this.isMakers() || this.isWholeSales() || this.isDistribution())
        ? _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-notes" } }),
                  _vm._v(" サブアカウント登録 ")
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        [
                          _c(
                            "CForm",
                            _vm._l(this.subAccounts, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  attrs: { item: item, index: index }
                                },
                                [
                                  item.isNew
                                    ? _c(
                                        "CRow",
                                        [
                                          _c("CCol", { attrs: { md: "10" } }, [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "35%",
                                                  float: "left"
                                                }
                                              },
                                              [
                                                _c("CInput", {
                                                  attrs: {
                                                    label: "メールアドレス",
                                                    horizontal: "",
                                                    type: "email",
                                                    placeholder:
                                                      "taro@coupon.com",
                                                    autocomplete: "email",
                                                    isValid: _vm.checkSubAccountEmail(
                                                      index
                                                    ),
                                                    invalidFeedback:
                                                      "メールアドレスを正しく入力してください。"
                                                  },
                                                  model: {
                                                    value: item.email,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "email",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.email"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "30%",
                                                  float: "left"
                                                }
                                              },
                                              [
                                                _c("CInput", {
                                                  attrs: {
                                                    label: "パスワード",
                                                    horizontal: "",
                                                    type: "password",
                                                    placeholder: "Password",
                                                    autocomplete:
                                                      "new-password",
                                                    isValid: _vm.checkSubAccountPassword(
                                                      index
                                                    ),
                                                    invalidFeedback:
                                                      "8文字以上かつ数字、英小文字それぞれ1文字以上含む値を入力してください。"
                                                  },
                                                  model: {
                                                    value: item.password,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "password",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.password"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "35%",
                                                  float: "left"
                                                }
                                              },
                                              [
                                                _c("CInput", {
                                                  attrs: {
                                                    label: "パスワード確認",
                                                    horizontal: "",
                                                    type: "password",
                                                    placeholder: "Password",
                                                    autocomplete:
                                                      "new-password",
                                                    isValid: _vm.checkSubAccountConfirmPassword(
                                                      index
                                                    ),
                                                    invalidFeedback:
                                                      "パスワードが一致しません。"
                                                  },
                                                  model: {
                                                    value: item.confirmPassword,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "confirmPassword",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.confirmPassword"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]),
                                          _c(
                                            "CCol",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "CButton",
                                                {
                                                  staticClass: "ml-3 mb-3",
                                                  attrs: { color: "dark" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteSubAccount(
                                                        item.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("×")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !item.isNew
                                    ? _c(
                                        "CRow",
                                        [
                                          _c("CCol", { attrs: { md: "10" } }, [
                                            _vm._v(
                                              " メールアドレス ： " +
                                                _vm._s(item.email) +
                                                " "
                                            )
                                          ]),
                                          _c(
                                            "CCol",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "CButton",
                                                {
                                                  staticClass: "ml-3 mb-3",
                                                  attrs: { color: "dark" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteSubAccount(
                                                        item.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("×")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        {
                          staticClass: "mb-3 mb-xl-0 text-center",
                          attrs: { col: "2" }
                        },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: {
                                variant: "outline",
                                shape: "pill",
                                color: "secondary",
                                size: "sm"
                              },
                              on: { click: _vm.addSubAccount }
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v("入力欄を追加 ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "m-3",
                          attrs: { color: "info" },
                          on: { click: _vm.submitSubAccounts }
                        },
                        [_vm._v(" 登録する ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !this.isNew && _vm.isDistribution()
        ? _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-notes" } }),
                  _vm._v(" 店舗登録 ")
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        [
                          _c(
                            "CForm",
                            _vm._l(this.stores, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  attrs: { item: item, index: index }
                                },
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c("CCol", { attrs: { md: "6" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "20%",
                                              float: "left"
                                            }
                                          },
                                          [
                                            item.storeCode === 0
                                              ? _c("CInput", {
                                                  attrs: {
                                                    horizontal: "",
                                                    placeholder: "店舗コード",
                                                    isValid: _vm.checkStoreCode(
                                                      index
                                                    ),
                                                    invalidFeedback:
                                                      "店舗コードを入力してください。"
                                                  },
                                                  model: {
                                                    value:
                                                      item.displayStoreCode,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "displayStoreCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.displayStoreCode"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.storeCode > 0
                                              ? _c("CInput", {
                                                  attrs: {
                                                    horizontal: "",
                                                    placeholder: "店舗コード",
                                                    isValid: _vm.checkStoreCode(
                                                      index
                                                    ),
                                                    invalidFeedback:
                                                      "店舗コードを入力してください。",
                                                    plaintext: ""
                                                  },
                                                  model: {
                                                    value:
                                                      item.displayStoreCode,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "displayStoreCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.displayStoreCode"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "40%",
                                              float: "left"
                                            }
                                          },
                                          [
                                            _c("CInput", {
                                              attrs: {
                                                horizontal: "",
                                                placeholder: "店舗名",
                                                isValid: _vm.checkStoreName(
                                                  index
                                                ),
                                                invalidFeedback:
                                                  "店舗名を入力してください。"
                                              },
                                              model: {
                                                value: item.name,
                                                callback: function($$v) {
                                                  _vm.$set(item, "name", $$v)
                                                },
                                                expression: "item.name"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "20%",
                                              float: "left"
                                            }
                                          },
                                          [
                                            _c("CInput", {
                                              staticStyle: {
                                                "margin-right": "-20px"
                                              },
                                              attrs: {
                                                horizontal: "",
                                                placeholder: "100-0001"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.onUpdatePostalCode(
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: item.postal_code,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "postal_code",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.postal_code"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "20%",
                                              float: "left"
                                            }
                                          },
                                          [
                                            _c("CInput", {
                                              staticStyle: {
                                                "margin-right": "-40px"
                                              },
                                              attrs: {
                                                horizontal: "",
                                                placeholder: "都道府県"
                                              },
                                              model: {
                                                value: item.prefectures,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "prefectures",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.prefectures"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("CCol", { attrs: { md: "6" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "50%",
                                              float: "left"
                                            }
                                          },
                                          [
                                            _c("CInput", {
                                              attrs: {
                                                lg: "5",
                                                horizontal: "",
                                                placeholder: "市区町村以下"
                                              },
                                              model: {
                                                value: item.address,
                                                callback: function($$v) {
                                                  _vm.$set(item, "address", $$v)
                                                },
                                                expression: "item.address"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "40%",
                                              float: "left"
                                            }
                                          },
                                          [
                                            _c("CInput", {
                                              attrs: {
                                                type: "date",
                                                horizontal: ""
                                              },
                                              model: {
                                                value: item.opening_date,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "opening_date",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.opening_date"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "10%",
                                              float: "left"
                                            }
                                          },
                                          [
                                            _c(
                                              "CButton",
                                              {
                                                staticClass: "ml-3 mb-3",
                                                attrs: { color: "dark" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteStore(
                                                      item.id
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("×")]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        {
                          staticClass: "mb-3 mb-xl-0 text-center",
                          attrs: { col: "2" }
                        },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: {
                                variant: "outline",
                                shape: "pill",
                                color: "secondary",
                                size: "sm"
                              },
                              on: { click: _vm.addStore }
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v("入力欄を追加 ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "m-3",
                          attrs: { color: "info" },
                          on: { click: _vm.submitStores }
                        },
                        [_vm._v(" 登録する ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !this.isNew && _vm.isDistribution()
        ? _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-notes" } }),
                  _vm._v(" 帳合登録 ")
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        [
                          _c(
                            "CForm",
                            _vm._l(this.collaborates, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  attrs: { item: item, index: index }
                                },
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c("CCol", { attrs: { md: "6" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "50%",
                                              float: "left"
                                            }
                                          },
                                          [
                                            _c("CSelect", {
                                              attrs: {
                                                label: "メーカー",
                                                horizontal: "",
                                                options: _vm.makerPullDown,
                                                placeholder: "Please select"
                                              },
                                              on: {
                                                "update:value": function(
                                                  $event
                                                ) {
                                                  return _vm.onUpdateMaker(
                                                    index,
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: item.maker_id,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "maker_id",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.maker_id"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "50%",
                                              float: "left"
                                            }
                                          },
                                          [
                                            _c("CSelect", {
                                              attrs: {
                                                label: "　卸",
                                                horizontal: "",
                                                options: _vm.wholesalePullDown,
                                                placeholder: "Please select"
                                              },
                                              on: {
                                                "update:value": function(
                                                  $event
                                                ) {
                                                  return _vm.onUpdateWholesale(
                                                    index,
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: item.wholesale_id,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "wholesale_id",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.wholesale_id"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("CCol", { attrs: { md: "5" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "50%",
                                              float: "left"
                                            }
                                          },
                                          [
                                            _c("CInput", {
                                              staticClass: "ml-3",
                                              attrs: {
                                                label: "From",
                                                type: "date",
                                                horizontal: "",
                                                lazy: true
                                              },
                                              model: {
                                                value: item.start_date,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "start_date",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.start_date"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "50%",
                                              float: "left"
                                            }
                                          },
                                          [
                                            _c("CInput", {
                                              staticClass: "ml-3",
                                              attrs: {
                                                label: "To",
                                                type: "date",
                                                horizontal: "",
                                                lazy: true
                                              },
                                              model: {
                                                value: item.end_date,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "end_date",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.end_date"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]),
                                      _c(
                                        "CCol",
                                        { attrs: { md: "1" } },
                                        [
                                          _c(
                                            "CButton",
                                            {
                                              staticClass: "ml-3 mb-3",
                                              attrs: { color: "dark" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteCollaborate(
                                                    item.id
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("×")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        {
                          staticClass: "mb-3 mb-xl-0 text-center",
                          attrs: { col: "2" }
                        },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: {
                                variant: "outline",
                                shape: "pill",
                                color: "secondary",
                                size: "sm"
                              },
                              on: { click: _vm.addCollaborates }
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v("入力欄を追加 ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "m-3",
                          attrs: { color: "info" },
                          on: { click: _vm.submitCollaborates }
                        },
                        [_vm._v(" 登録する ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !this.isNew && _vm.isRegister()
        ? _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-notes" } }),
                  _vm._v(" 店舗登録 ")
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        [
                          _c(
                            "CForm",
                            _vm._l(this.registerStores, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  attrs: { item: item, index: index }
                                },
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        {
                                          attrs: { lg: "4", md: "5", sm: "5" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("CSelect", {
                                                attrs: {
                                                  label: "小売",
                                                  horizontal: "",
                                                  options:
                                                    _vm.distributionsPullDown,
                                                  placeholder: "Please select",
                                                  value: item.distribution_id
                                                },
                                                on: {
                                                  "update:value": [
                                                    function($event) {
                                                      return _vm.$set(
                                                        item,
                                                        "distribution_id",
                                                        $event
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.onUpdateDistributions(
                                                        index,
                                                        $event
                                                      )
                                                    }
                                                  ]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "CCol",
                                        {
                                          attrs: { lg: "4", md: "5", sm: "5" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("CSelect", {
                                                attrs: {
                                                  label: "店舗",
                                                  horizontal: "",
                                                  options: item.storePullDown,
                                                  placeholder: "Please select",
                                                  value: item.id
                                                },
                                                on: {
                                                  "update:value": [
                                                    function($event) {
                                                      return _vm.$set(
                                                        item,
                                                        "id",
                                                        $event
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.onUpdateRegisterStore(
                                                        index,
                                                        $event
                                                      )
                                                    }
                                                  ]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "CCol",
                                        {
                                          attrs: { lg: "4", md: "2", sm: "2" }
                                        },
                                        [
                                          _c(
                                            "CButton",
                                            {
                                              staticClass: "ml-3 mb-3",
                                              attrs: { color: "dark" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteRegisterStore(
                                                    item.id
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("×")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        {
                          staticClass: "mb-3 mb-xl-0 text-center",
                          attrs: { col: "2" }
                        },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: {
                                variant: "outline",
                                shape: "pill",
                                color: "secondary",
                                size: "sm"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addRegisterStore()
                                }
                              }
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v("入力欄を追加 ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "m-3",
                          attrs: { color: "info" },
                          on: {
                            click: function($event) {
                              return _vm.submitRegisterStores()
                            }
                          }
                        },
                        [_vm._v(" 登録する ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ]
        },
        [_vm._m(0)]
      ),
      _c(
        "CModal",
        {
          attrs: {
            title: "アカウント削除",
            color: "danger",
            show: _vm.dangerModal
          },
          on: {
            "update:show": function($event) {
              _vm.dangerModal = $event
            },
            ok: function($event) {
              return _vm.onClickedDelete()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function($event) {
                          _vm.dangerModal = false
                        }
                      }
                    },
                    [_vm._v("キャンセル")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { color: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.onClickedDelete()
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [_vm._v(" 本当に削除しますか？ ")]
      ),
      _c(
        "CModal",
        {
          attrs: {
            title: "パスワードリセット",
            color: "info",
            show: _vm.confirmPasswordResetModal
          },
          on: {
            "update:show": function($event) {
              _vm.confirmPasswordResetModal = $event
            },
            ok: function($event) {
              return _vm.onClickedPasswordReset()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function($event) {
                          _vm.confirmPasswordResetModal = false
                        }
                      }
                    },
                    [_vm._v("キャンセル")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { color: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.onClickedPasswordReset()
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        [
                          _vm._v(" 新しいパスワード："),
                          _c("CInput", {
                            attrs: {
                              isValid: _vm.checkResetPassword,
                              invalidFeedback:
                                "8文字以上かつ数字、英小文字それぞれ1文字以上含む値を入力してください"
                            },
                            model: {
                              value: _vm.initialPassword,
                              callback: function($$v) {
                                _vm.initialPassword = $$v
                              },
                              expression: "initialPassword"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "alert alert-danger", attrs: { role: "alert" } },
            [_vm._v(" まだパスワードは変更されていません。 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "gray" } }, [
      _c("div", { staticClass: "sk-wave", attrs: { id: "loading-sp" } }, [
        _c("div", { staticClass: "sk-wave-rect" }),
        _c("div", { staticClass: "sk-wave-rect" }),
        _c("div", { staticClass: "sk-wave-rect" }),
        _c("div", { staticClass: "sk-wave-rect" }),
        _c("div", { staticClass: "sk-wave-rect" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }