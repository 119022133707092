<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-notes"/> アカウント登録
      </CCardHeader>
      <CForm>
      <CCardBody>
        <CRow>
          <CCol lg="6">
            <CInput
              label="ユーザーコード"
              placeholder=""
              v-model=account.code
            />

            <CInput
              label="会社名"
              placeholder="オープンクーポン株式会社"
              v-model=account.name
            />

            <CInput
              v-if="!isEditOthers()"
              label="メールアドレス"
              type="email"
              :lazy="true"
              :isValid="checkIfValid('email')"
              placeholder="taro@coupon.com"
              autocomplete="email"
              invalidFeedback="メールアドレスを正しく入力してください。"
              v-model=auth.email
            />
            <CInput
              v-if="isEditOthers()"
              label="メールアドレス"
              type="email"
              v-model=auth.email
              plaintext
            />

            <CRow v-if="!isEditOthers()">
              <CCol md="6">
                <CInput
                  label="パスワード"
                  :lazy="true"
                  :isValid="checkIfValid('password')"
                  type="password"
                  placeholder="Password"
                  autocomplete="new-password"
                  invalidFeedback="8文字以上かつ数字、英小文字それぞれ1文字以上含む値を入力してください"
                  v-model=auth.password
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="パスワード確認入力"
                  :lazy="true"
                  :isValid="checkIfValid('confirmPassword')"
                  type="password"
                  placeholder="Password"
                  autocomplete="new-password"
                  invalidFeedback="パスワードが一致しません。"
                  v-model=auth.confirmPassword
                />
              </CCol>
            </CRow>

              <CSelect
                v-if="this.isNew"
                label="権限"
                :options="options"
                placeholder="Please select"
                :value.sync=account.role
                @update:value=onUpdateRole($event)
              />

              <div v-if="!this.isNew">権限&nbsp;&nbsp;{{getRoleName(account.role)}}</div>

              <div class="form-group form-row">
                <CCol sm="2" tag="label" class="col-form-label" v-if="isDistribution()">
                  公開日
                </CCol>
                <CCol sm="10" class="form-inline" v-if="isDistribution()">
                  <CInput
                    type="date"
                    horizontal
                    v-model="account.opening_date"
                    :isValid="checkOpeningDate()"
                    invalidFeedback="不正な日付です。"
                  />
                </CCol>
              </div>

            <br><br>

            <CRow class="align-items-center">
              <CCol col="2" class="text-left">
                  <CButton 
                    color="info" 
                    @click="submit"
                  >
                    登録
                  </CButton>
              </CCol>
              <CCol col="4" class="text-left" v-if="this.isDeletable()">
                  <CButton 
                    color="info" 
                    @click="confirmPasswordReset"
                    class="mr-1"
                  >
                    パスワードリセット
                  </CButton>
              </CCol>
              <CCol col="6" class="text-right" v-if="this.isDeletable()">
                <CButton
                  color="danger"
                  @click="dangerModal = true"
                  class="mr-1"
                  >削除</CButton>
              </CCol>
            </CRow>
            <br/>
          </CCol>

          <CCol lg="6">
            <CRow>
              <CCol sm="4">
                <CInput
                  label="郵便番号"
                  placeholder="100-0001"
                  v-model=account.postal_code
                  @change="onUpdatePostalCode($event)"
                />
              </CCol>
              <CCol sm="8">
                <CSelect
                  label="都道府県"
                  :options="prefectures"
                  v-model="account.prefCode"
                  placeholder="Please select"
                />
              </CCol>          
            </CRow>
            <CInput
              label="住所"
              placeholder="市区町村以下"
              v-model=account.address
            />
            <CInput
              label="電話番号"
              placeholder="0300000000"
              v-model=account.phone_number
            />
            <CInput
              v-if="isDistribution() && this.account.companyCode === 0"
              label="企業コード"
              :lazy="true"
              :isValid="checkIfValid('displayCompanyCode')"
              invalidFeedback="企業コードを正しく入力してください。"
              v-model=auth.displayCompanyCode
            />
            <CInput
              v-if="isDistribution() && this.account.companyCode > 0"
              label="企業コード"
              :lazy="true"
              :isValid="checkIfValid('displayCompanyCode')"
              invalidFeedback="企業コードを正しく入力してください。"
              v-model=auth.displayCompanyCode
              plaintext
            />
          </CCol>
        </CRow>
      </CCardBody>
      </CForm>
    </CCard>

    <CCard v-if="!this.isNew && (isMakers() || isWholeSales())">
      <CCardHeader>
        <CIcon name="cil-notes"/> 取引店舗登録
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <CForm>
              <div v-for="(item, index) in this.distributions" v-bind:item="item" v-bind:index="index" v-bind:key="item.id">
                <CRow>
                  <CCol md="5">
                    <CSelect
                      horizontal
                      :options="distributionsPullDown"
                      placeholder="Please select"
                      :value.sync="item.id"
                      @update:value="onUpdateDistributionsForMaker(index, $event)"
                    />
                  </CCol>
                  <CCol md="1">
                    <CButton class="ml-3 mb-3" color="dark" @click="deleteDistribution(item.id)">×</CButton>
                  </CCol>
                </CRow>
              </div>
            </CForm>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="2" class="mb-3 mb-xl-0">
            <CButton variant="outline" shape="pill" color="secondary" size="sm" @click="addDistribution">+</CButton>入力欄を追加
          </CCol>
        </CRow>
        <CRow>
          <CButton 
            class="m-3"
            color="info" 
            @click="submitDistributions"
          >
            登録する
          </CButton>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard v-if="!this.isNew && (this.isMakers() || this.isWholeSales() || this.isDistribution())">
      <CCardHeader>
        <CIcon name="cil-notes"/> サブアカウント登録
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <CForm>
              <div v-for="(item, index) in this.subAccounts" v-bind:item="item" v-bind:index="index" v-bind:key="item.id">
                <CRow v-if="item.isNew">
                  <CCol md="10">
                    <div style="width: 35%; float: left;">
                    <CInput
                      label="メールアドレス"
                      horizontal
                      type="email"
                      placeholder="taro@coupon.com"
                      autocomplete="email"
                      v-model=item.email
                      :isValid="checkSubAccountEmail(index)"
                      invalidFeedback="メールアドレスを正しく入力してください。"
                    />
                    </div>
                    <div style="width: 30%; float: left;">
                    <CInput
                      label="パスワード"
                      horizontal
                      type="password"
                      placeholder="Password"
                      autocomplete="new-password"
                      v-model=item.password
                      :isValid="checkSubAccountPassword(index)"
                      invalidFeedback="8文字以上かつ数字、英小文字それぞれ1文字以上含む値を入力してください。"
                    />
                    </div>
                    <div style="width: 35%; float: left;">
                    <CInput
                      label="パスワード確認"
                      horizontal
                      type="password"
                      placeholder="Password"
                      autocomplete="new-password"
                      v-model=item.confirmPassword
                      :isValid="checkSubAccountConfirmPassword(index)"
                      invalidFeedback="パスワードが一致しません。"
                    />
                    </div>
                  </CCol>
                  <CCol md="2">
                    <CButton class="ml-3 mb-3" color="dark" @click="deleteSubAccount(item.id)">×</CButton>
                  </CCol>
                </CRow>
                <CRow v-if="!item.isNew">
                  <CCol md="10">
                    メールアドレス　：　{{item.email}}
                  </CCol>
                  <CCol md="2">
                    <CButton class="ml-3 mb-3" color="dark" @click="deleteSubAccount(item.id)">×</CButton>
                  </CCol>
                </CRow>
              </div>
            </CForm>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="2" class="mb-3 mb-xl-0 text-center">
            <CButton variant="outline" shape="pill" color="secondary" size="sm" @click="addSubAccount">+</CButton>入力欄を追加
          </CCol>
        </CRow>
        <CRow>
          <CButton 
            class="m-3"
            color="info" 
            @click="submitSubAccounts"
          >
            登録する
          </CButton>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard v-if="!this.isNew && isDistribution()">
      <CCardHeader>
        <CIcon name="cil-notes"/> 店舗登録
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <CForm>
              <div v-for="(item, index) in this.stores" v-bind:item="item" v-bind:index="index" v-bind:key="item.id">
                <CRow>
                  <CCol md="6">
                    <div style="width: 20%; float: left;">
                      <CInput 
                        v-if="item.storeCode === 0"
                        horizontal
                        placeholder="店舗コード"
                        v-model=item.displayStoreCode
                        :isValid="checkStoreCode(index)"
                        invalidFeedback="店舗コードを入力してください。"/>
                      <CInput
                        v-if="item.storeCode > 0"
                        horizontal
                        placeholder="店舗コード"
                        v-model=item.displayStoreCode
                        :isValid="checkStoreCode(index)"
                        invalidFeedback="店舗コードを入力してください。"
                        plaintext/>
                    </div>
                    <div style="width: 40%; float: left;">
                      <CInput
                        horizontal
                        placeholder="店舗名"
                        v-model=item.name
                        :isValid="checkStoreName(index)"
                        invalidFeedback="店舗名を入力してください。"/>
                    </div>
                    <div style="width: 20%; float: left;">
                      <CInput
                        style="margin-right:-20px;"
                        horizontal
                        placeholder="100-0001"
                        v-model=item.postal_code
                        @change="onUpdatePostalCode($event)"
                      />
                    </div>
                    <div style="width: 20%; float: left;">
                      <CInput
                        style="margin-right:-40px;"
                        horizontal
                        placeholder="都道府県"
                        v-model=item.prefectures
                      />
                    </div>
                  </CCol>
                  <CCol md="6">
                    <div style="width: 50%; float: left;">
                      <CInput
                        lg="5"
                        horizontal
                        placeholder="市区町村以下"
                        v-model=item.address
                      />
                    </div>
                    <div style="width: 40%; float: left;">
                      <CInput
                        type="date"
                        horizontal
                        v-model="item.opening_date"
                      />
                    </div>
                    <div style="width: 10%; float: left;">
                      <CButton class="ml-3 mb-3" color="dark" @click="deleteStore(item.id)">×</CButton>
                    </div>
                  </CCol>
                </CRow>
              </div>
            </CForm>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="2" class="mb-3 mb-xl-0 text-center">
            <CButton variant="outline" shape="pill" color="secondary" size="sm" @click="addStore">+</CButton>入力欄を追加
          </CCol>
        </CRow>
        <CRow>
          <CButton 
            class="m-3"
            color="info" 
            @click="submitStores"
          >
            登録する
          </CButton>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard v-if="!this.isNew && isDistribution()">
      <CCardHeader>
        <CIcon name="cil-notes"/> 帳合登録
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <CForm>
              <div v-for="(item, index) in this.collaborates" v-bind:item="item" v-bind:index="index" v-bind:key="item.id">
                <CRow>
                  <CCol md="6">
                    <div style="width: 50%; float: left;  ">
                    <CSelect
                      label="メーカー"
                      horizontal
                      :options="makerPullDown"
                      placeholder="Please select"
                      v-model="item.maker_id"
                      @update:value="onUpdateMaker(index, $event)"
                    />
                    </div>
                    <div style="width: 50%; float: left;  ">
                    <CSelect
                      label="　卸"
                      horizontal
                      :options="wholesalePullDown"
                      placeholder="Please select"
                      v-model="item.wholesale_id"
                      @update:value="onUpdateWholesale(index, $event)"
                    />
                    </div>
                  </CCol>
                  <CCol md="5">
                    <div style="width: 50%; float: left;  ">
                    <CInput
                      class="ml-3"
                      label="From"
                      type="date"
                      horizontal
                      v-model="item.start_date"
                      :lazy="true"
                    />
                    </div>
                    <div style="width: 50%; float: left;  ">
                    <CInput
                      class="ml-3"
                      label="To"
                      type="date"
                      horizontal
                      v-model="item.end_date"
                      :lazy="true"
                    />
                    </div>
                  </CCol>
                  <CCol md="1">
                    <CButton class="ml-3 mb-3" color="dark" @click="deleteCollaborate(item.id)">×</CButton>
                  </CCol>
                </CRow>
              </div>
            </CForm>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="2" class="mb-3 mb-xl-0 text-center">
            <CButton variant="outline" shape="pill" color="secondary" size="sm" @click="addCollaborates">+</CButton>入力欄を追加
          </CCol>
        </CRow>
        <CRow>
          <CButton 
            class="m-3"
            color="info" 
            @click="submitCollaborates"
          >
            登録する
          </CButton>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard v-if="!this.isNew && isRegister()">
      <CCardHeader>
        <CIcon name="cil-notes"/> 店舗登録
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <CForm>
              <div v-for="(item, index) in this.registerStores" v-bind:item="item" v-bind:index="index" v-bind:key="item.id">
                <CRow>
                  <CCol lg="4" md="5" sm="5">
                    <div>
                      <CSelect
                        label="小売"
                        horizontal
                        :options="distributionsPullDown"
                        placeholder="Please select"
                        :value.sync="item.distribution_id"
                        @update:value="onUpdateDistributions(index, $event)"
                      />
                    </div>
                  </CCol>
                  <CCol lg="4" md="5" sm="5">
                    <div>
                      <CSelect
                        label="店舗"
                        horizontal
                        :options="item.storePullDown"
                        placeholder="Please select"
                        :value.sync="item.id"
                        @update:value="onUpdateRegisterStore(index, $event)"
                      />
                    </div>
                  </CCol>
                  <CCol lg="4" md="2" sm="2">
                    <CButton class="ml-3 mb-3" color="dark" @click="deleteRegisterStore(item.id)">×</CButton>
                  </CCol>
                </CRow>
              </div>
            </CForm>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="2" class="mb-3 mb-xl-0 text-center">
            <CButton variant="outline" shape="pill" color="secondary" size="sm" @click="addRegisterStore()">+</CButton>入力欄を追加
          </CCol>
        </CRow>
        <CRow>
          <CButton 
            class="m-3"
            color="info" 
            @click="submitRegisterStores()"
          >
            登録する
          </CButton>
        </CRow>
      </CCardBody>
    </CCard>

    <div v-show="false">
      <div id="gray">
        <div id="loading-sp" class="sk-wave">
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
        </div>
      </div>
    </div>

    <CModal
      title="アカウント削除"
      color="danger"
      :show.sync="dangerModal"
      @ok="onClickedDelete()"
    >
      本当に削除しますか？
      <template #footer>
        <CButton @click="dangerModal = false" color="secondary">キャンセル</CButton>
        <CButton @click="onClickedDelete()" color="danger">OK</CButton>
      </template>
    </CModal>

    <CModal
      title="パスワードリセット"
      color="info"
      :show.sync="confirmPasswordResetModal"
      @ok="onClickedPasswordReset()"
    >
      <CCard>
        <CCardBody>
          <CRow>
            <CCol>
              新しいパスワード：<CInput v-model=initialPassword :isValid="checkResetPassword" invalidFeedback="8文字以上かつ数字、英小文字それぞれ1文字以上含む値を入力してください"/>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <div class="alert alert-danger" role="alert">
        まだパスワードは変更されていません。
      </div>
      <template #footer>
        <CButton @click="confirmPasswordResetModal = false" color="secondary">キャンセル</CButton>
        <CButton @click="onClickedPasswordReset()" color="danger">OK</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import Vue from 'vue';
import axios from 'axios';
import firebase from '@firebase/app';
import { UserRole } from "@/common/const";
import { uuid } from 'vue-uuid';
import jpPrefecture from 'jp-prefecture';

const checkCompanyCode = function(companyCodeStr) {
  var pattern = /^\d*$/
  if (companyCodeStr != null && companyCodeStr.length > 0 && pattern.test(companyCodeStr)) {
    return true
  }
  return false
}

Vue.use(uuid);

export default {
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.auth.$invalid },
    isDirty () { return this.$v.auth.$anyDirty },
  },
  mixins: [validationMixin],
  validations() {
    let auth = {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8),
        strongPass: helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z]).{8,}/)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
    if (!this.isEditOthers()) {
      if (this.isDistribution()) {
        auth['displayCompanyCode'] = {checkCompanyCode}
        return {
          auth: auth
        }
      } else {
        return {
          auth: auth
        }
      }
    } else {
      if (this.isDistribution()) {
        return {
          auth: {
            displayCompanyCode: {
              checkCompanyCode
            }
          }
        }
      } else {
        return {
          auth: {
          }
        }
      }
    }
  },
  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.auth[fieldName]
      if (!field.$dirty) {
        return null
      } 
      return !(field.$invalid || field.$model === '')
    },

    validate () {
      this.$v.$touch()
    },

    reset () {
      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    
    getEmptyForm () {
      return {
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        password: "",
        confirmPassword: "",
        displayCompanyCode: "",
        accept: false
      }
    },

    init () {
      // 初期化
      this.isNew = true
      this.account = JSON.parse(JSON.stringify(this.accountOrigin))
      if (this.isDistribution()) {
        this.account.opening_date = null
      }
      this.stores = []
      this.subAccounts = []
      this.distributions = []
      this.deleteStores = []
      this.deleteSubAccounts = []
      this.deleteDistributions = []
      this.collaborates = []
      this.deleteCollaborates = []
      this.auth = JSON.parse(JSON.stringify(this.authOrigin))
      this.confirmPassword = ''
      this.prefectures = jpPrefecture.getAll("pref", ["id", "name"]).map(function(item) {return {value : item.id.toString(), label : item.name}})
      this.$store.commit("hideLoading")

      if (typeof this.$route.params.id === "undefined") {
        return
      }

      // プルダウン用のメーカー一覧取得
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", UserRole.DISTRIBUTIONS)
        .get()
        .then(
          function(result) {
            let tmp = [];
            result.forEach(function(doc) {
              const parentId = doc.data().parent_id
              if (parentId == null || parentId == "") {
                tmp.push({ label: doc.data().name, value: doc.data().id });
              }
            });
            this.distributionsPullDown = tmp;
          }.bind(this)
        );
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", UserRole.MAKERS)
        .get()
        .then(
          function(result) {
            let tmp = [];
            result.forEach(function(doc) {
              const parentId = doc.data().parent_id
              if (parentId == null || parentId == "") {
                tmp.push({ label: doc.data().name, value: doc.data().id })
              }
            });
            this.makerPullDown = tmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
          }.bind(this)
        );
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", UserRole.WHOLESALE)
        .get()
        .then(
          function(result) {
            let tmp = [];
            result.forEach(function(doc) {
              const parentId = doc.data().parent_id
              if (parentId == null || parentId == "") {
                tmp.push({ label: doc.data().name, value: doc.data().id });
              }
            });
            this.wholesalePullDown = tmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
          }.bind(this)
        );
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", UserRole.DISTRIBUTIONS)
        .get()
        .then(
          function(result) {
            let tmp = [];
            result.forEach(function(doc) {
              const parentId = doc.data().parent_id
              if (parentId == null || parentId == "") {
                tmp.push({ label: doc.data().name, value: doc.data().id });
              }
            });
            this.distributionPullDown = tmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
          }.bind(this)
        );

      this.fetchUser()
    },
    async fetchUser() {
      // idありの場合は編集なので取得処理
      let id
      let event
      if (this.$route.params.id == 'me') {
        id = firebase.auth().currentUser.uid
        const userDoc = await firebase.firestore().collection('users').doc(id).get()
        const parent_id = userDoc.data().parent_id
        if (parent_id) {
          id = parent_id
        }
        event = firebase.app().functions('asia-northeast1').httpsCallable('me')
      } else {
        id = this.$route.params.id
        event = firebase.app().functions('asia-northeast1').httpsCallable('users')
      }

      this.$store.commit("showLoading")

      // ユーザーデータ取得
      event({uid: id}).then(function(res) {
        this.isNew = false
        this.auth.uid = res.data.auth.uid
        this.auth.email = res.data.auth.email
        this.account = res.data.user
        if (this.account.companyCode === undefined) {
          this.account.companyCode = 0
        }
        this.auth.displayCompanyCode = ('000000' + this.account.companyCode).slice(-6)
        if (this.account.opening_date != null) {
          this.account.opening_date = this.formatDate(new Date(this.account.opening_date._seconds * 1000))
        }

        if (this.isDistribution()) {
          this.fetchStores()
          this.fetchCollaborates()
        }
        if (this.isMakers() || this.isWholeSales() || this.isDistribution()) {
          this.fetchSubAccounts()
        }
        if (this.isMakers() || this.isWholeSales()) {
          this.fetchDistributions()
        }
        if (this.isRegister()) {
          this.fetchRegisterStores()
        }
        this.$store.commit("hideLoading")
      }.bind(this));
    },
    async fetchDistributions() {
      // ひもづけ小売情報取得
      const distributionSnapshot = await firebase.firestore().collection('users').doc(this.account.id).collection('distributions').get()
      for (const distributionDoc of distributionSnapshot.docs) {
        let distribution = {}
        distribution.id = distributionDoc.id
        this.distributions.push(distribution)
      }
    },
    createDistribution() {
      const tmp = JSON.parse(JSON.stringify(this.distributionOrigin))
      return tmp
    },
    addDistribution() {
      this.distributions.push(this.createDistribution())
    },
    deleteDistribution(id) {
      let tmp = []
      let deleteTmp = null
      this.distributions.map(function(distribution) {
        if (distribution.id == id) {
          deleteTmp = distribution
        } else {
          tmp.push(distribution)
        }
      })
      this.distributions = tmp
      this.deleteDistributions.push(deleteTmp)
    },
    submitDistributions () {
      this.saveDistributions()
    },
    async saveDistributions() {
      this.$store.commit("showLoading")

      const batch = firebase.firestore().batch()
      const accountId = this.account.id

      // 更新
      this.distributions.forEach(function(distribution) {
        const ref = firebase.firestore().collection('users').doc(accountId).collection('distributions').doc(distribution.id)
        batch.set(ref, distribution)
      })

      // 削除
      this.deleteDistributions.forEach(function(distribution) {
        const ref = firebase.firestore().collection('users').doc(accountId).collection('distributions').doc(distribution.id)
        batch.delete(ref)
      })

      await batch.commit()

      this.$store.commit("hideLoading")
    },
    async onUpdateDistributionsForMaker(index, value) {
      this.$store.commit("showLoading")
      this.distributions[index].id = value
      this.$store.commit("hideLoading")
    },
    async onUpdatePostalCode(value) {
      var uri = '/getAddress/search?zipcode=' + value;
      const response = await axios.get(uri)
      if (response.data.results && response.data.results[0]) {
        this.account.prefCode = response.data.results[0].prefcode
        this.account.address = response.data.results[0].address2 + response.data.results[0].address3
      }
    },
    fetchStores() {
      // 店舗情報取得
      firebase.firestore().collection('stores').where('created_user_id', '==', this.account.id).orderBy('storeCode').get().then(function(result) {
        let stores = []
        result.forEach(function(doc) {
          const store = doc.data()
          store.displayStoreCode = ('000000' + store.storeCode).slice(-6);
          if (store.opening_date != null) {
            store.opening_date = this.formatDate(new Date(store.opening_date.seconds * 1000))
          }
          stores.push(store)
        }.bind(this))
        this.stores = stores

        // なかった場合は1つ新規作成
        if (this.stores.length == 0) {
          const tmp = this.createStore()
          this.stores.push(tmp)
        }
      }.bind(this))
    },
    async fetchRegisterStores() {
      // 店舗情報取得
      const storeSnapshot = await firebase.firestore().collection('stores').where('register_id', '==', this.account.id).get()
      for (const storeDoc of storeSnapshot.docs) {
        const storeData = storeDoc.data()
        const companySnapshot = await firebase.firestore().collection('users')
          .where('role', '==', UserRole.DISTRIBUTIONS)
          .where('companyCode', '==', storeData.companyCode)
          .get()

        let store = {}
        store.id = storeDoc.id
        companySnapshot.forEach(doc => {
          const parentId = doc.data().parent_id
          if (parentId == null || parentId == "") {
            store.distribution_id = doc.id
          }
        })
        store.storePullDown = await this.getStorePullDown(store.distribution_id)
        this.registerStores.push(store)
      }
    },
    createStore() {
      const tmp = JSON.parse(JSON.stringify(this.storeOrigin))
      tmp.id = uuid.v4()
      tmp.companyCode = this.account.companyCode
      tmp.created_user_id = this.account.id
      return tmp
    },
    addStore() {
      this.stores.push(this.createStore())
    },
    deleteStore(id) {
      let tmp = []
      let deleteTmp = null
      this.stores.map(function(store) {
        if (store.id == id) {
          deleteTmp = store
        } else {
          tmp.push(store)
        }
      })
      this.stores = tmp
      this.deleteStores.push(deleteTmp)
    },
    createRegisterStore() {
      const tmp = {id: null, distribution_id: null, storePullDown:[]}
      return tmp
    },
    addRegisterStore() {
      this.registerStores.push(this.createRegisterStore())
    },
    deleteRegisterStore(id) {
      let tmp = []
      let deleteTmp = null
      this.registerStores.map(function(store) {
        if (store.id == id) {
          deleteTmp = store
        } else {
          tmp.push(store)
        }
      })
      this.registerStores = tmp
    },

    async fetchSubAccounts() {
      // サブアカウント情報取得
      let uid = firebase.auth().currentUser.uid
      if (this.$route.params.id == 'me') {
        const userDoc = await firebase.firestore().collection('users').doc(uid).get()
        const parent_id = userDoc.data().parent_id
        if (parent_id) {
          uid = parent_id
        }
      } else {
        uid = this.$route.params.id
      }
      firebase.firestore().collection('users').where('parent_id', '==', uid).get().then(function(result) {
        let subAccounts = []
        result.forEach(function(doc) {
          const subAccount = doc.data()
          subAccount.isNew = false
          subAccounts.push(subAccount)
        })
        this.subAccounts = subAccounts

        // なかった場合は1つ新規作成
        if (this.subAccounts.length == 0) {
          const tmp = this.createSubAccount()
          this.subAccounts.push(tmp)
        }
      }.bind(this))
    },
    createSubAccount() {
      const tmp = JSON.parse(JSON.stringify(this.subAccountOrigin))
      tmp.id = uuid.v4()
      return tmp
    },
    addSubAccount() {
      this.subAccounts.push(this.createSubAccount())
    },
    deleteSubAccount(id) {
      let tmp = []
      let deleteTmp = null
      this.subAccounts.map(function(subAccount) {
        if (subAccount.id == id) {
          deleteTmp = subAccount
        } else {
          tmp.push(subAccount)
        }
      })
      this.subAccounts = tmp
      this.deleteSubAccounts.push(deleteTmp)
    },

    fetchCollaborates() {
      // 帳合情報取得
      firebase.firestore().collection('collaborates').where('created_user_id', '==', this.account.id).get().then(function(result) {
        let collaborates = []
        result.forEach(function(doc) {
          const collabo = doc.data()
          collaborates.push(collabo)
        })
        this.collaborates = collaborates

        // なかった場合は1つ新規作成
        if (this.collaborates.length == 0) {
          const tmp = this.createCollaborates()
          this.collaborates.push(tmp)
        }
      }.bind(this))
    },
    createCollaborates() {
      const tmp = JSON.parse(JSON.stringify(this.collaborateOrigin))
      tmp.id = uuid.v4()
      tmp.companyCode = this.account.companyCode
      tmp.created_user_id = this.account.id
      return tmp
    },
    addCollaborates() {
      this.collaborates.push(this.createCollaborates())
    },
    deleteCollaborate(id) {
      let tmp = []
      let deleteTmp = null
      this.collaborates.map(function(collabo) {
        if (collabo.id == id) {
          deleteTmp = collabo
        } else {
          tmp.push(collabo)
        }
      })
      this.collaborates = tmp
      this.deleteCollaborates.push(deleteTmp)
    },
    onUpdateRole(value) {
      this.account.role = value
    },
    async saveAccount() {
      this.$store.commit("showLoading")

      if (this.isNew) {
        const providers = await firebase.auth().fetchSignInMethodsForEmail(this.auth.email)
        if (providers.findIndex(p => p === firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
          alert("メールアドレスはすでに登録されています")
          this.$store.commit("hideLoading")
          return
        }
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const openingDate = new Date(this.account.opening_date)
      openingDate.setHours(0, 0, 0, 0);
      if (!this.isNew && this.account.opening_date != null && openingDate > today) {
        const membersSnapShot = await firebase.firestore().collection("members").get()
        const storesSnapshot = await firebase.firestore().collection("stores").where("companyCode", "==", Number(this.account.companyCode)).get()
        const storeIds = storesSnapshot.docs.map(store => store.data().id)
        const members = membersSnapShot.docs.filter(member => {
          const favorites = member.data().favorites
          return (favorites != null && Object.keys(favorites).some(key => storeIds.includes(key)));
        });

        if (members.length > 0) {
          const message = "公開日が本日よりあとに設定されています。\nユーザーのお気に入りから削除されますが、よろしいですか？"
          if (!confirm(message)) {
            this.$store.commit("hideLoading")
            return
          }
          const batch = firebase.firestore().batch()
          for (const memberDoc of members) {
            let favorites = memberDoc.data().favorites
            for (const storeId of storeIds) {
              delete favorites[storeId]
            }
            batch.update(memberDoc.ref, { favorites: favorites})
          }
          await batch.commit()
        }
      }

      delete this.auth.confirmPassword
      // 他人のパスワードは更新しない
      if (this.isEditOthers()) {
        delete this.auth.password
      }

      // 認証情報の保存
      if (this.auth.displayCompanyCode != null && this.auth.displayCompanyCode.length > 0) {
        this.account.companyCode = parseInt(this.auth.displayCompanyCode, 10);
      }

      delete this.auth.displayCompanyCode
      if (this.isNew) {
        const tmp = JSON.parse(JSON.stringify(this.auth))
        delete tmp.uid
        const event = firebase.app().functions('asia-northeast1').httpsCallable('createUser')
        await event({auth: tmp, user: this.account})
      } else {
        if (this.$route.params.id == 'me') {
          const event = firebase.app().functions('asia-northeast1').httpsCallable('updateMe')
          await event({auth: this.auth, user: this.account})
          // 自分の認証情報を更新した場合は保存後再ログインしておく
          if (this.auth.uid == firebase.auth().currentUser.uid) {
            await firebase.auth().signInWithEmailAndPassword(this.auth.email, this.auth.password)
          }
          this.$store.commit("hideLoading")
        } else {
          const event = firebase.app().functions('asia-northeast1').httpsCallable('updateUser')
          await event({auth: this.auth, user: this.account})
          this.$store.commit("hideLoading")
        }
      }

      // 保存後は一覧画面に遷移
      this.$store.commit("hideLoading")
      const link = (this.$route.params.id == 'me') ? `/` : `/accounts/tables/makers`
      this.$router.push({path: link})

      return
    },
    submitSubAccounts() {
      for (let i = 0; i < this.subAccounts.length; i++) {
        if (!this.checkSubAccountEmail(i) || !this.checkSubAccountPassword(i) || !this.checkSubAccountConfirmPassword(i)) {
          alert('サブアカウントの情報を正しく入力してください。')
          return
        }
      }
      this.saveSubAccounts()
    },
    async saveSubAccounts() {
      this.$store.commit("showLoading")

      for (let subAccount of this.subAccounts) {
        if (!subAccount.isNew) {
          continue
        }
        const providers = await firebase.auth().fetchSignInMethodsForEmail(subAccount.email)
        if (providers.findIndex(p => p === firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
          alert("メールアドレスはすでに登録されています")
          this.$store.commit("hideLoading")
          return
        }
      }

      // 認証情報の削除
      const eventDelete = firebase.app().functions('asia-northeast1').httpsCallable('deleteSubUser')
      for (let deleteSubAccount of this.deleteSubAccounts) {
        if (deleteSubAccount.isNew) {
          continue
        }
        await eventDelete({user: deleteSubAccount})
      }
      this.deleteSubAccounts.splice(0, this.deleteSubAccounts.length)

      // 認証情報の保存
      const event = firebase.app().functions('asia-northeast1').httpsCallable('createSubUser')
      let subAccountUser = Object.assign({}, this.account);
      delete subAccountUser.id
      subAccountUser.parent_id = this.account.id
      for (let subAccount of this.subAccounts) {
        if (!subAccount.isNew) {
          continue
        }
        subAccountUser.email = subAccount.email
        await event({auth: subAccount, user: subAccountUser})
        subAccount.isNew = false
      }

      this.$store.commit("hideLoading")

      return
    },
    submit () {
      this.$v.$touch()
      if (!this.isValid || !this.checkOpeningDate()) {
        return
      }
      this.saveAccount()
    },
    submitStores () {
      this.saveStores()
    },
    async saveStores() {
      this.$store.commit("showLoading")

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let canUpdate = true
      let storeIds = []
      const membersSnapShot = await firebase.firestore().collection("members").get()
      await Promise.all(this.stores.map(async function(store) {
        const openingDate = new Date(store.opening_date)
        openingDate.setHours(0, 0, 0, 0);
        if (store.id != null && store.opening_date != null && store.opening_date != '' && openingDate > today) {
          storeIds.push(store.id)
          const members = membersSnapShot.docs.filter(member => {
            const favorites = member.data().favorites
            return (favorites != null && Object.keys(favorites).some(key => store.id == key));
          })
          if (members.length > 0) {
            const message = store.name + "店の公開日が本日よりあとに設定されています。\nユーザーのお気に入りから削除されますが、よろしいですか？"
            if (!confirm(message)) {
              canUpdate = false
            }
          }
        }
      }))
      if (!canUpdate) {
        this.$store.commit("hideLoading")
        return
      }
      const members = membersSnapShot.docs.filter(member => {
        const favorites = member.data().favorites
        return (favorites != null && Object.keys(favorites).some(key => storeIds.includes(key)));
      });
      if (members.length > 0) {
        const favDeleteBatch = firebase.firestore().batch()
        for (const memberDoc of members) {
          let favorites = memberDoc.data().favorites
          for (const storeId of storeIds) {
            delete favorites[storeId]
          }
          favDeleteBatch.update(memberDoc.ref, { favorites: favorites})
        }
        await favDeleteBatch.commit()
      }

      const batch = firebase.firestore().batch()

      // 更新
      this.stores.forEach(function(store) {
        store.storeCode = parseInt(store.displayStoreCode, 10);
        if (store.opening_date != null && store.opening_date != '') {
          store.opening_date = new Date(store.opening_date)
        } else {
          store.opening_date = null
        }
        const ref = firebase.firestore().collection('stores').doc(store.id)
        batch.set(ref, store)
      })

      // 削除 (storeCouponsも削除)
      this.deleteStores.forEach(function(store) {
        const ref = firebase.firestore().collection('stores').doc(store.id)
        batch.delete(ref)
        firebase.firestore().collection('stores').doc(store.id).collection('storeCoupons').get().then(function(storeCouponQuerySnapshot) {
          storeCouponQuerySnapshot.forEach(function(storeCouponDoc) {
            const couponRef = firebase.firestore().collection('stores').doc(store.id).collection('storeCoupons').doc(storeCouponDoc.id)
            couponRef.delete()
          })
        })
      })

      await batch.commit()

      this.$store.commit("hideLoading")

      // 保存後は一覧画面に遷移
      //const link = `/accounts/tables/makers`
      //this.$router.push({path: link})
      this.$router.go({path: this.$router.currentRoute.path, force: true});
    },
    submitCollaborates() {
      this.saveCollaborates()
    },
    async saveCollaborates() {
      this.$store.commit("showLoading")

      const batch = firebase.firestore().batch()

      // 更新
      this.collaborates.forEach(function(collabo) {
        const ref = firebase.firestore().collection('collaborates').doc(collabo.id)
        batch.set(ref, collabo)
      })

      // 削除
      this.deleteCollaborates.forEach(function(collabo) {
        const ref = firebase.firestore().collection('collaborates').doc(collabo.id)
        batch.delete(ref)
      })

      await batch.commit()

      this.$store.commit("hideLoading")

      // 保存後は一覧画面に遷移
      //const link = `/accounts/tables/makers`
      //this.$router.push({path: link})
      this.$router.go({path: this.$router.currentRoute.path, force: true});
    },
    submitRegisterStores() {
      this.saveRegisterStores()
    },
    async saveRegisterStores() {
      this.$store.commit("showLoading")

      const batch = firebase.firestore().batch()

      // 削除
      const storesSnapshot = await firebase.firestore().collection('stores')
        .where("register_id", "==", this.account.id)
        .get()
      
      storesSnapshot.forEach(doc => {
        const data = doc.data()
        data.register_id = null
        batch.set(doc.ref, data)
      })

      // 更新
      for (const store of this.registerStores) {
        if (store.id == null) continue
        const ref = firebase.firestore().collection('stores').doc(store.id)
        const snapshot = await ref.get()
        const data = snapshot.data()
        data.register_id = this.account.id
        batch.set(ref, data)
      }

      await batch.commit()

      this.$store.commit("hideLoading")

      // 保存後は一覧画面に遷移
      //const link = `/accounts/tables/makers`
      //this.$router.push({path: link})
      this.$router.go({path: this.$router.currentRoute.path, force: true});
    },
    getRoleName(id) {
      return UserRole.getName(id)
    },
    isDistribution() {
      return this.account.role == UserRole.DISTRIBUTIONS
    },
    isMakers() {
      return this.account.role == UserRole.MAKERS
    },
    isWholeSales() {
      return this.account.role == UserRole.WHOLESALE
    },
    isRegister() {
      return this.account.role == UserRole.REGISTER
    },
    isEditOthers() {
      return !this.isNew && this.$route.params.id !== 'me'
    },
    isDeletable() {
      return this.isEditOthers() && (this.$route.params.id !== firebase.auth().currentUser.uid)
    },
    onUpdateMaker(index, value) {
      this.collaborates[index].maker_id = value;
    },
    onUpdateWholesale(index, value) {
      this.collaborates[index].wholesale_id = value;
    },
    onUpdateMakerForStore(index, value) {
      this.collaborates[index].maker_id = value;
    },
    onUpdateStore(index, value) {
      this.stores[index].store_id = value;
    },
    async onUpdateDistributions(index, value) {
      this.$store.commit("showLoading")
      this.registerStores[index].distribution_id = value
      const storePullDown = await this.getStorePullDown(value)
      this.registerStores[index].storePullDown = storePullDown
      this.$store.commit("hideLoading")
    },
    async getStorePullDown(distributionId) {
      const distributionSnapshot = await firebase.firestore().collection("users").doc(distributionId).get()
      const companyCode = distributionSnapshot.data().companyCode

      let storePullDown = [];
      const storeSnapshot = await firebase.firestore().collection("stores")
        .where("companyCode", "==", companyCode)
        .get()
      storeSnapshot.forEach(doc => {
        storePullDown.push({ label: doc.data().name, value: doc.id })
      })
      return storePullDown
    },
    onUpdateRegisterStore(index, value) {
      this.registerStores[index].id = value;
    },
    checkOpeningDate() {
      if (this.account.opening_date == "") this.account.opening_date = null
      if (this.account.opening_date == null) return true;
      const d1 = new Date(this.account.opening_date);
      return d1.getTime() ? true : false;
    },
    checkMaker(index) {
      return this.collaborates[index].maker_id !== undefined && this.collaborates[index].maker_id !== null
    },
    checkStoreCode(index) {
      var pattern = /^\d*$/
      if (this.stores[index].displayStoreCode != null && this.stores[index].displayStoreCode.length > 0 && pattern.test(this.stores[index].displayStoreCode)) {
        return true
      }
      return false
    },
    checkStoreName(index) {
      if (this.stores[index].name != null && this.stores[index].name.length > 0) {
        return true
      }
      return false
    },
    checkSubAccountEmail(index) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(this.subAccounts[index].email);
    },
    checkSubAccountPassword(index) {
      if (!this.subAccounts[index].isNew) {
        return true
      }
      const pattern = /(?=.*\d)(?=.*[a-z]).{8,}/
      if (this.subAccounts[index].password != null && this.subAccounts[index].password.length > 0 && pattern.test(this.subAccounts[index].password)) {
        return true
      }
      return false
    },
    checkSubAccountConfirmPassword(index) {
      if (!this.subAccounts[index].isNew) {
        return true
      }
      if (this.subAccounts[index].password == this.subAccounts[index].confirmPassword) {
        return true
      }
      return false
    },
    async onClickedDelete() {
      this.$store.commit("showLoading");

      let id;
      let event;
      let currentUser;
      id = firebase.auth().currentUser.uid
      event = firebase.app().functions('asia-northeast1').httpsCallable('me');
      await event({uid: id}).then(function(res) {
        currentUser = res.data.user;
      });

      event = firebase.app().functions('asia-northeast1').httpsCallable('deleteUser')
      await event({auth: this.auth, user: this.account, currentUser: currentUser})
      await firebase
        .firestore()
        .collection('users')
        .doc(this.$route.params.id)
        .delete();

      this.$store.commit("hideLoading")
      const link = `/accounts/tables/makers`
      this.$router.push({path: link})
      return;
    },
    confirmPasswordReset() {
      this.confirmPasswordResetModal = true;
      
      var letters = 'abcdefghijklmnopqrstuvwxyz'
      var numbers = '0123456789'
      var passstr  = letters + letters.toUpperCase() + numbers
      var len = 8
      var password = ''
      while (!this.checkResetPassword(password)) {
        password = ''
        for (var i = 0; i < len; i++) {
          password += passstr.charAt(Math.floor(Math.random() * passstr.length));
        }
      }
      this.initialPassword = password
    },
    checkResetPassword(val) {
      var pattern = /(?=.*\d)(?=.*[a-z]).{8,}/
      if (val != null && val.length > 0 && pattern.test(val)) {
        return true
      }
      return false
    },
    formatDate(dt) {
      const y = dt.getFullYear()
      const m = ('00' + (dt.getMonth()+1)).slice(-2)
      const d = ('00' + dt.getDate()).slice(-2)
      return (y + '-' + m + '-' + d)
    },
    async onClickedPasswordReset() {
      this.$store.commit("showLoading");

      let id;
      let event;
      let currentUser;
      id = firebase.auth().currentUser.uid
      const userDoc = await firebase.firestore().collection('users').doc(id).get()
      const parent_id = userDoc.data().parent_id
      if (parent_id) {
        id = parent_id
      }
      event = firebase.app().functions('asia-northeast1').httpsCallable('me');
      await event({uid: id}).then(function(res) {
        currentUser = res.data.user;
      });

      event = firebase.app().functions('asia-northeast1').httpsCallable('updateUserPassword')
      await event({auth: this.auth, currentUser: currentUser, newPassword: this.initialPassword})

      this.$store.commit("hideLoading");
      const link = `/accounts/tables/makers`
      this.$router.push({path: link})
      return;
    }
  },

  name: 'Forms',
  data () {
    return {
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: [
        {value: UserRole.ADMIN, label: UserRole.getName(UserRole.ADMIN)},
        {value: UserRole.MAKERS, label: UserRole.getName(UserRole.MAKERS)},
        {value: UserRole.DISTRIBUTIONS, label: UserRole.getName(UserRole.DISTRIBUTIONS)},
        {value: UserRole.WHOLESALE, label: UserRole.getName(UserRole.WHOLESALE)},
        {value: UserRole.REGISTER, label: UserRole.getName(UserRole.REGISTER)},
      ],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3', 'Option 4',
        { 
          value: ['some value', 'another value'], 
          label: 'Selected option'
        }
      ],
      selectedOption: ['some value', 'another value'],

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom'],
      accountOrigin: {
        id: '',
        code: '',
        name: '',
        role: 1,
        registered: null,
        postal_code: '',
        phone_number: '',
        prefCode: '',
        address: '',
        companyCode: 0,
        parent_id: '',
      },
      storeOrigin: {
        id: '',
        name: '',
        created_user_id: '',
        displayStoreCode: '',
        storeCode: 0,
        postal_code: '',
        prefectures: '',
        address: '',
        opening_date: null,
        companyCode: 0,
        register_id: null,
      },
      subAccountOrigin: {
        id: '',
        email: '',
        password: '',
        confirmPassword: '',
        isNew: true,
      },
      collaborateOrigin: {
        id: '',
        created_user_id: '',
        companyCode: '',
        maker_id: '',
        wholesale_id: '',
        start_date: '',
        end_date: '',
      },
      distributionOrigin: {
        id: '',
        name: '',
      },
      account: {},
      stores: [],
      subAccounts: [],
      distributions: [],
      deleteStores: [],
      deleteSubAccounts: [],
      deleteDistributions: [],
      registerStores: [],
      collaborates: [],
      deleteCollaborates: [],
      authOrigin: {
        uid: '',
        email: '',
        password: '',
        confirmPassword: '',
        displayCompanyCode: ''
      },
      auth: {},
      isNew: true,
      distributionsPullDown: [],
      makerPullDown: [],
      wholesalePullDown: [],
      distributionPullDown: [],
      dangerModal: false,
      confirmPasswordResetModal: false,
      initialPassword: '',
      prefectures : [],
    }
  },
  created () {
    this.init()
  },
  watch: {
    '$route': function () {
      this.init()
    }
  },
}
</script>
